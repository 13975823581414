<template>
	<Card>
		<template #content>
			<h1 class="page-header">Om webbplatsen Grottor.se</h1>
			<p>
				Grottor.se är under uppbyggnad.
			</p>
			<p class="mb-4">
				Webbplatsen drivs av Sveriges Speleologförbund (SSF) som är en förening för alla som är intresserade av grottor
				i Sverige. Förbundet startade 1966 och initiativtagare var Leander Tell som också blev SSF:s förste ordförande.
				Mer information hittar du på föreningens hemsida:
				<a href="https://speleo.se" target="_blank">speleo.se</a>
			</p>
			<p class="mb-4">
				På Grottor.se har vi som ambition att samla all information om Sveriges alla grottor. Informationen kommer ifrån
				Sveriges Speleologförbunds grottdatabas. Det är en databas som har byggt upp av föreningens medlemmar under
				många år.
			</p>
			<p class="mb-4">
				En del av informationen är öppen för alla, men för att ta del av all information om grottorna behöver du vara
				medlem i SSF.
			</p>
			<p class="mb-4">
				Grottor.se registrerades 2005 av Rune Magnusson. Rune blev medlem i förbundet 1982 och var under många år
				styrelsemedlem samt webbredaktör för speleo.se. Grottor.se var Runes privata sida. Här samlades bilder från
				utflykter, information om SSF:s fjällmöten och årsmöten, samt länkar till andra sidor för grottintresserade.<br />
				<!--Rune Magnusson huvudintresse är grottorna i fjällen och därmed har fjällvandring utvecklats att bli viktig för mig.-->
			</p>
			<p class="mb-4">
				2020 donerade Rune generöst Grottor.se till SSF. Lasse Löfquist tog över stafettpinnen för att utveckla
				webbplatsen med målet att använda den för att göra förbundets fantastiska grottdatabas mer tillgänglig och mer
				lättanvänd, både för allmänheten och för förbundets medlemmar.
			</p>
		</template>
	</Card>
</template>
